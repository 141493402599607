//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import responsiveElement from 'kolibri.coreVue.mixins.responsiveElement';
import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';

export default {
  name: 'Overlay',
  mixins: [responsiveElement, responsiveWindow],
  props: {
    cols: {
      type: Number,
      required: true,
    },
    gutterWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      top: 0,
      left: 0,
      right: 0,
    };
  },
  computed: {
    columnWidth() {
      return this.elementWidth / this.cols;
    },
    marginLeftStyle() {
      return {
        left: '0px',
        width: `${this.left}px`,
      };
    },
    marginRightStyle() {
      return {
        left: `${this.right}px`,
        right: '0px',
      };
    },
  },
  watch: {
    windowWidth() {
      this.updateOffset();
    },
    windowHeight() {
      this.updateOffset();
    },
  },
  methods: {
    gutterStyle(n) {
      const left = this.columnWidth * n - this.gutterWidth / 2 + this.left;
      return {
        left: `${left}px`,
        width: `${this.gutterWidth}px`,
      };
    },
    updateOffset() {
      const rect = this.$el.getBoundingClientRect();
      this.top = rect.top;
      this.left = rect.left;
      this.right = rect.right;
    },
  },
};

