//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'KeenUiSnackbar',
  mixins: [themeMixin],
  props: {
    message: String,
    action: String,
    transition: {
      type: String,
      default: 'slide', // 'slide' or 'fade'
    },
  },

  computed: {
    transitionName() {
      return 'ui-snackbar--transition-' + this.transition;
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
    },

    onActionClick() {
      this.$emit('action-click');
    },

    onEnter() {
      this.$emit('show');
    },

    onLeave() {
      this.$emit('hide');
    },
  },
};

