//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState, mapActions } from 'vuex';
import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import KButton from 'kolibri.coreVue.components.KButton';
import ClipboardJS from 'clipboard';

export default {
  name: 'TechnicalTextBlock',
  $trs: {
    copyToClipboardButtonPrompt: 'Copy to clipboard',
    copiedToClipboardConfirmation: 'Copied to clipboard',
    downloadAsTextPrompt: 'Or download as a text file',
  },
  components: {
    KButton,
  },
  mixins: [themeMixin],
  props: {
    text: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: Number,
      required: false,
    },
    minHeight: {
      type: Number,
      default: 72,
    },
  },
  computed: {
    ...mapState({
      error: state => state.core.error,
    }),
    clipboardCapable() {
      return ClipboardJS.isSupported();
    },
    dynamicHeightStyle() {
      return {
        height: `${16 + this.text.split('\n').length * 18}px`,
        maxHeight: `${this.maxHeight}px`,
        minHeight: `${this.minHeight}px`,
      };
    },
  },
  mounted() {
    if (this.clipboardCapable) {
      this.clipboard = new ClipboardJS(this.$refs.copyButton.$el, {
        text: () => this.text,
        // needed because modal changes browser focus
        container: this.$refs.textBox,
      });

      this.clipboard.on('success', () => {
        this.createSnackbar({
          text: this.$tr('copiedToClipboardConfirmation'),
          autoDismiss: true,
        });
      });
    }
  },
  destroyed() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  },
  methods: {
    ...mapActions(['createSnackbar']),
  },
};

