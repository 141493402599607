//
//
//
//
//
//
//
//
//
//
//
//


import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';

export default {
  name: 'KPageContainer',
  mixins: [responsiveWindow],
};

