var render, staticRenderFns
import script from "./CoreTable.vue?vue&type=script&lang=js&"
export * from "./CoreTable.vue?vue&type=script&lang=js&"
import style0 from "./CoreTable.vue?vue&type=style&index=0&id=0046a4e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0046a4e0",
  null
  
)

component.options.__file = "CoreTable.vue"
export default component.exports