//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import shave from 'shave';
import debounce from 'lodash/debounce';
import responsiveElement from 'kolibri.coreVue.mixins.responsiveElement';
import KButton from 'kolibri.coreVue.components.KButton';
import KTooltip from 'kolibri.coreVue.components.KTooltip';

export default {
  name: 'TextTruncator',
  components: {
    KButton,
    KTooltip,
  },
  mixins: [responsiveElement],
  props: {
    text: {
      type: String,
      required: true,
    },
    maxHeight: {
      type: Number,
      required: true,
      validator(value) {
        return value > 0;
      },
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
    showViewMore: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      textIsTruncated: false,
      viewAllText: false,
    };
  },
  computed: {
    tooltipText() {
      if (!this.showTooltip || this.showViewMore || !this.textIsTruncated) {
        return null;
      }
      return this.text;
    },
    currentDimensions() {
      return {
        text: this.text,
        maxHeight: this.maxHeight,
        elementWidth: this.elementWidth,
        elementHeight: this.elementHeight,
      };
    },
    debouncedHandleUpdate() {
      return debounce(this.handleUpdate, 50);
    },
  },
  watch: {
    currentDimensions() {
      this.debouncedHandleUpdate();
    },
  },
  methods: {
    handleUpdate() {
      // TODO make "View Less" disappear when user expands window
      // and text isn't truncated any more.
      shave(this.$refs.shaveEl, this.maxHeight);
      this.$nextTick(() => {
        this.textIsTruncated = Boolean(this.$el.querySelector('.js-shave'));
      });
    },
  },
  $trs: {
    viewMoreButtonPrompt: 'View more',
    viewLessButtonPrompt: 'View less',
  },
};

