//
//
//
//
//
//
//
//
//
//


export default {
  name: 'PointsIcon',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
};

