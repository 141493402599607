//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';
import debounce from 'lodash/debounce';
import KButton from 'kolibri.coreVue.components.KButton';

/**
 * Used to focus attention on a singular action/task
 */
export default {
  name: 'KModal',
  components: {
    KButton,
  },
  mixins: [responsiveWindow, themeMixin],
  $trs: {
    // error alerts
    errorAlert: 'Error in { title }',
  },
  props: {
    /**
     * The title of the modal
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * The text of the submit button
     */
    submitText: {
      type: String,
      required: false,
    },
    /**
     * The text of the cancel button
     */
    cancelText: {
      type: String,
      required: false,
    },
    /**
     * Disable the submit button
     */
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable the cancel button
     */
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * How wide the modal should be.
     * Small - 300 px.
     * Medium - 450px.
     * Large - 100%.
     */
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator(val) {
        return ['small', 'medium', 'large'].includes(val);
      },
    },
    /**
     * Toggles error message indicator in title
     */
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastFocus: null,
      maxContentHeight: '1000',
      scrollShadow: false,
      delayedEnough: false,
    };
  },
  computed: {
    modalSizeStyles() {
      return {
        'max-width': `${this.maxModalWidth - 32}px`,
        'max-height': `${this.windowHeight - 32}px`,
      };
    },
    maxModalWidth() {
      if (this.windowWidth < 1000) {
        return this.windowWidth;
      }
      return 1000;
    },
    contentSectionMaxHeight() {
      return { 'max-height': `${this.maxContentHeight}px` };
    },
  },
  beforeMount() {
    this.lastFocus = document.activeElement;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.modal && !this.$refs.modal.contains(document.activeElement)) {
        this.focusModal();
      }
    });
    window.addEventListener('focus', this.focusElementTest, true);
    window.addEventListener('scroll', this.preventScroll, true);
    window.setTimeout(() => (this.delayedEnough = true), 500);
  },
  updated() {
    this.updateContentSectionStyle();
  },
  destroyed() {
    window.removeEventListener('focus', this.focusElementTest, true);
    window.removeEventListener('scroll', this.preventScroll, true);
    // Wait for events to finish propagating before changing the focus.
    // Otherwise the `lastFocus` item receives events such as 'enter'.
    window.setTimeout(() => this.lastFocus.focus());
  },
  methods: {
    /**
     * Calculate the max-height of the content section of the modal
     * If there is not enough vertical space, create a vertically scrollable area and a
     * scroll shadow
     */
    updateContentSectionStyle: debounce(function() {
      if (this.$refs.title && this.$refs.actions) {
        this.maxContentHeight =
          this.windowHeight -
          this.$refs.title.clientHeight -
          this.$refs.actions.clientHeight -
          32;
        this.scrollShadow = this.maxContentHeight < this.$refs.content.scrollHeight;
      }
    }, 50),
    emitCancelEvent() {
      if (!this.cancelDisabled) {
        // Emitted when the cancel button is clicked or the esc key is pressed
        this.$emit('cancel');
      }
    },
    emitSubmitEvent() {
      if (!this.submitDisabled) {
        // Emitted when the submit button or the enter key is pressed
        this.$emit('submit');
      }
    },
    handleEnter() {
      if (this.delayedEnough) {
        this.emitSubmitEvent();
      }
    },
    focusModal() {
      this.$refs.modal.focus();
    },
    focusElementTest(event) {
      // switching apps - not relevant
      if (event.target === window) {
        return;
      }
      // not sure when this would be true
      if (!this.$refs.modal) {
        return;
      }
      // addresses https://github.com/learningequality/kolibri/issues/3824
      if (
        event.target === this.$refs.modal ||
        this.$refs.modal.contains(event.target.activeElement)
      ) {
        return;
      }
      // focus has escaped the modal - put it back!
      if (!this.$refs.modal.contains(event.target)) {
        this.focusModal();
      }
    },
    preventScroll(event) {
      event.preventDefault();
    },
  },
};

