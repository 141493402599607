//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import UiIcon from 'keen-ui/src/UiIcon';
import UiIconButton from 'kolibri.coreVue.components.UiIconButton';
/**
 * Used to filter items via text input
 */
export default {
  name: 'KFilterTextbox',
  $trs: {
    filter: 'filter',
    clear: 'clear',
  },
  components: {
    UiIcon,
    UiIconButton,
  },
  mixins: [themeMixin],
  props: {
    /**
     * v-model
     */
    value: {
      type: String,
    },
    /**
     * Placeholder
     */
    placeholder: {
      type: String,
      required: true,
    },
    /**
     * Whether to autofocus
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        /**
         * Emits input event with new value
         */
        this.$emit('input', val);
      },
    },
    kFilterPlaceHolderStyle() {
      return {
        '::placeholder': {
          color: this.$coreTextAnnotation,
        },
      };
    },
  },
};

