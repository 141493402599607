//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

/**
 * Used to show indeterminate loading
 */
export default {
  name: 'KCircularLoader',
  mixins: [themeMixin],
  props: {
    /**
     * Whether there should be a delay before the loader displays
     */
    delay: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 32,
    },
    type: {
      type: String,
      default: 'indeterminate', // 'indeterminate' or 'determinate'
    },
    progress: {
      type: Number,
      default: 0,
    },
    stroke: Number,
    autoStroke: {
      type: Boolean,
      default: true,
    },
    disableTransition: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return [`ui-progress-circular--type-${this.type}`];
    },

    strokeDashArray() {
      const circumference = 2 * Math.PI * this.radius;

      // Use first 3 decimal places, rounded as appropriate
      return Math.round(circumference * 1000) / 1000;
    },

    strokeDashOffset() {
      const progress = this.moderateProgress(this.progress);
      const circumference = 2 * Math.PI * this.radius;

      return ((100 - progress) / 100) * circumference;
    },

    radius() {
      const stroke = this.stroke ? this.stroke : 4;
      return (this.size - stroke) / 2;
    },

    calculatedStroke() {
      if (this.stroke) {
        return this.stroke;
      }

      if (this.autoStroke) {
        return parseInt(this.size / 8, 10);
      }

      return 4;
    },
  },

  methods: {
    moderateProgress(progress) {
      if (isNaN(progress) || progress < 0) {
        return 0;
      }

      if (progress > 100) {
        return 100;
      }

      return progress;
    },
  },
};

