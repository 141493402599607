//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { validateContentNodeKind } from 'kolibri.utils.validators';
import { ContentNodeKinds, USER } from 'kolibri.coreVue.vuex.constants';
import UiIcon from 'keen-ui/src/UiIcon';
import KTooltip from 'kolibri.coreVue.components.KTooltip';

export default {
  name: 'ContentIcon',
  $trs: {
    topic: 'Topic',
    channel: 'Channel',
    exercise: 'Exercise',
    video: 'Video',
    audio: 'Audio',
    document: 'Document',
    html5: 'App',
    exam: 'Quiz',
    lesson: 'Lesson',
    user: 'User',
  },
  components: {
    UiIcon,
    KTooltip,
  },
  props: {
    kind: {
      type: String,
      required: true,
      validator(value) {
        return validateContentNodeKind(value, [USER]);
      },
    },
    colorstyle: {
      type: String,
      default: 'action',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ContentNodeKinds() {
      return ContentNodeKinds;
    },
    USER() {
      return USER;
    },
    colorClass() {
      return `color-${this.colorStyle}`;
    },
    tooltipText() {
      const kindToLabeLMap = {
        [ContentNodeKinds.TOPIC]: 'topic',
        [ContentNodeKinds.CHANNEL]: 'channel',
        [ContentNodeKinds.EXERCISE]: 'exercise',
        [ContentNodeKinds.VIDEO]: 'video',
        [ContentNodeKinds.AUDIO]: 'audio',
        [ContentNodeKinds.DOCUMENT]: 'document',
        [ContentNodeKinds.HTML5]: 'html5',
        [ContentNodeKinds.EXAM]: 'exam',
        [ContentNodeKinds.LESSON]: 'lesson',
        [USER]: 'user',
      };
      const label = kindToLabeLMap[this.kind];
      return label ? this.$tr(label) : '';
    },
  },
  methods: {
    is(kind) {
      return this.kind === kind;
    },
  },
};

