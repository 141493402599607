//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import range from 'lodash/range';
import getYear from 'date-fns/get_year';
import { now } from 'kolibri.utils.serverClock';
import CoreInfoIcon from 'kolibri.coreVue.components.CoreInfoIcon';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import { DemographicConstants } from 'kolibri.coreVue.vuex.constants';
import KSelect from 'kolibri.coreVue.components.KSelect';

const { NOT_SPECIFIED } = DemographicConstants;

// Take the last-known year to be the later of the copyright year,
// or the year of the server date
const firstYear = Math.max(Number(__copyrightYear), getYear(now()));

function makeYearOptions(max, min) {
  return range(max, min, -1).map(n => ({
    label: String(n),
    value: String(n),
  }));
}

const yearOptions = makeYearOptions(firstYear, 1900);

export default {
  name: 'BirthYearSelect',
  components: {
    CoreInfoIcon,
    KSelect,
  },
  mixins: [commonCoreStrings, responsiveWindowMixin],
  props: {
    value: {
      type: String,
    },
  },
  computed: {
    selected() {
      return this.options.find(o => o.value === this.value) || {};
    },
    options() {
      // The backend validation actually lets you pick years up to 3000, so we'll
      // fill in the gaps just in case a user was given a later date, e.g. via CSV
      let extraYears = [];
      if (Number(this.value) > firstYear) {
        extraYears = makeYearOptions(Number(this.value), firstYear - 1);
      }
      return [
        {
          value: NOT_SPECIFIED,
          label: this.coreString('birthYearNotSpecified'),
        },
        ...extraYears,
        ...yearOptions,
      ];
    },
    tooltipPlacement() {
      if (this.windowIsSmall) {
        return 'left';
      }
      return 'bottom';
    },
  },
  $trs: {
    placeholder: 'Select year',
    birthYearTooltip: 'Provide an estimate if you are unsure.',
    birthyearAriaLabel: 'About providing your birth year.',
  },
};

