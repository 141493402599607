//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import KModal from 'kolibri.coreVue.components.KModal';
import KRadioButton from 'kolibri.coreVue.components.KRadioButton';
import { currentLanguage } from 'kolibri.utils.i18n';
import KGrid from 'kolibri.coreVue.components.KGrid';
import KGridItem from 'kolibri.coreVue.components.KGridItem';
import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';
import languageSwitcherMixin from './mixin';

export default {
  name: 'LanguageSwitcherModal',
  components: {
    KModal,
    KGrid,
    KGridItem,
    KRadioButton,
  },
  mixins: [languageSwitcherMixin, responsiveWindow],
  $trs: {
    changeLanguageModalHeader: 'Change language',
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm',
  },
  data() {
    return {
      selectedLanguage: currentLanguage,
    };
  },
  computed: {
    splitLanguageOptions() {
      let secondCol = this.languageOptions;
      let firstCol = secondCol.splice(0, Math.ceil(secondCol.length / 2));

      return [firstCol, secondCol];
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    setLang() {
      this.switchLanguage(this.selectedLanguage);
    },
  },
};

