var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sideNav",staticClass:"side-nav-wrapper",on:{"keydown":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"esc",27,$event.key,"Escape")){ return null; }return _vm.toggleNav($event)}}},[_c('transition',{attrs:{"name":"side-nav"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navShown),expression:"navShown"}],staticClass:"side-nav",style:({
        width: (_vm.width + "px"),
        color: _vm.$coreTextDefault,
        backgroundColor: _vm.$coreBgLight,
      })},[_c('div',{staticClass:"side-nav-header",style:({
          height: _vm.headerHeight + 'px',
          width: (_vm.width + "px"), paddingTop: _vm.windowIsSmall ? '4px' : '8px',
          backgroundColor: _vm.$coreTextDefault,
        })},[_c('UiIconButton',{ref:"toggleButton",attrs:{"aria-label":_vm.$tr('closeNav'),"type":"secondary","color":"white","size":"large"},on:{"click":_vm.toggleNav}},[_c('svg',{staticClass:"side-nav-header-close",attrs:{"role":"presentation","focusable":"false","xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"}})])]),_c('span',{staticClass:"side-nav-header-name",style:({ color: _vm.$coreBgLight })},[_vm._v(_vm._s(_vm.$tr('kolibri')))])],1),_c('div',{staticClass:"side-nav-scrollable-area",style:({ top: (_vm.headerHeight + "px"), width: (_vm.width + "px") })},[_c('CoreMenu',{style:({ backgroundColor: _vm.$coreBgLight }),attrs:{"role":"navigation","hasIcons":true,"aria-label":_vm.$tr('navigationLabel')}},[_c('template',{slot:"options"},[_vm._l((_vm.menuOptions),function(component){return _c(component,{key:component.name,tag:"component"})}),_c('SideNavDivider')],2)],2),_c('div',{staticClass:"side-nav-scrollable-area-footer",style:({ color: _vm.$coreTextAnnotation })},[_c('CoreLogo',{staticClass:"side-nav-scrollable-area-footer-logo"}),_c('div',{staticClass:"side-nav-scrollable-area-footer-info"},[_c('p',[_vm._v(_vm._s(_vm.footerMsg))]),_c('p',[_vm._v("© "+_vm._s(_vm.copyrightYear)+" Learning Equality")]),_c('p',[_c('KButton',{ref:"privacyLink",staticClass:"privacy-link",attrs:{"text":_vm.$tr('privacyLink'),"appearance":"basic-link"},on:{"click":_vm.handleClickPrivacyLink}})],1)])],1)],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navShown),expression:"navShown"}],staticClass:"side-nav-backdrop",on:{"click":_vm.toggleNav}}),(_vm.privacyModalVisible)?_c('PrivacyInfoModal',{on:{"cancel":function($event){_vm.privacyModalVisible = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }