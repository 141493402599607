//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'CoreMenu',
  mixins: [themeMixin],
  props: {
    hasIcons: {
      type: Boolean,
      default: false,
    },
    hasSecondaryText: {
      type: Boolean,
      default: false,
    },
    containFocus: {
      type: Boolean,
      default: false,
    },
    raised: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'is-raised': this.raised,
        'has-icons': this.hasIcons,
        'has-secondary-text': this.hasSecondaryText,
      };
    },
  },

  methods: {
    selectOption(option) {
      if (option.disabled || option.type === 'divider') {
        return;
      }
      this.$emit('select', option);
      this.closeMenu();
    },

    closeMenu() {
      this.$emit('close');
    },

    redirectFocus(e) {
      e.stopPropagation();
      this.$el.querySelector('.ui-menu-option').focus();
    },
  },
};

