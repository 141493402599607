//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'ProgressBar',
  $trs: {
    label: 'Progress',
    pct: '{0, number, percent}',
  },
  mixins: [themeMixin],
  props: {
    progress: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    showPercentage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    percent() {
      return Math.max(Math.min(this.progress * 100, 100), 0);
    },
  },
};

