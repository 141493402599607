import { render, staticRenderFns } from "./TextTruncator.vue?vue&type=template&id=38a8ec0e&scoped=true&"
import script from "./TextTruncator.vue?vue&type=script&lang=js&"
export * from "./TextTruncator.vue?vue&type=script&lang=js&"
import style0 from "./TextTruncator.vue?vue&type=style&index=0&id=38a8ec0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a8ec0e",
  null
  
)

component.options.__file = "TextTruncator.vue"
export default component.exports