//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions } from 'vuex';
import KButton from 'kolibri.coreVue.components.KButton';
import KRouterLink from 'kolibri.coreVue.components.KRouterLink';
import ReportErrorModal from './ReportErrorModal';

export default {
  name: 'AppError',
  $trs: {
    defaultErrorHeader: 'Sorry! Something went wrong!',
    defaultErrorExitPrompt: 'Back to home',
    pageReloadPrompt: 'Refresh',
    defaultErrorMessage:
      'We care about your experience on Kolibri and are working hard to fix this issue.',
    defaultErrorResolution: 'Try refreshing this page or going back to the home page.',
    defaultErrorReportPrompt: 'Help us by reporting this error',
  },
  components: {
    KButton,
    KRouterLink,
    ReportErrorModal,
  },
  data() {
    return {
      showDetailsModal: false,
    };
  },
  methods: {
    ...mapActions(['handleError']),
    revealDetailsModal() {
      this.showDetailsModal = true;
    },
    hideDetailsModal() {
      this.showDetailsModal = false;
    },
    reloadPage() {
      // reloads without cache
      global.location.reload();
    },
    clearErrorState() {
      this.handleError('');
    },
  },
};

