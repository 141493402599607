//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { availableLanguages, currentLanguage } from 'kolibri.utils.i18n';
import KButton from 'kolibri.coreVue.components.KButton';
import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';
import UiIconButton from 'kolibri.coreVue.components.UiIconButton';
import languageSwitcherMixin from './mixin';
import LanguageSwitcherModal from './LanguageSwitcherModal';

export default {
  name: 'LanguageSwitcherList',
  $trs: {
    showMoreLanguagesSelector: 'More languages',
  },
  components: {
    KButton,
    LanguageSwitcherModal,
    UiIconButton,
  },
  mixins: [responsiveWindow, languageSwitcherMixin],
  data() {
    return {
      showLanguageModal: false,
    };
  },
  computed: {
    selectedLanguage() {
      return availableLanguages[currentLanguage];
    },
    numVisibleLanguages() {
      if (this.windowBreakpoint <= 2) {
        return 2;
      }
      return this.windowBreakpoint;
    },
    buttonLanguages() {
      const prioritized_languages = ['en', 'ar', 'es-es', 'hi-in', 'fr-fr', 'sw-tz'];
      return prioritized_languages
        .filter(lang => availableLanguages[lang] !== undefined)
        .filter(lang => lang !== currentLanguage)
        .map(lang => availableLanguages[lang])
        .slice(0, this.numVisibleLanguages)
        .sort(this.compareLanguages);
    },
  },
};

