import { render, staticRenderFns } from "./BirthYearDisplayText.vue?vue&type=template&id=b8e5218c&scoped=true&"
import script from "./BirthYearDisplayText.vue?vue&type=script&lang=js&"
export * from "./BirthYearDisplayText.vue?vue&type=script&lang=js&"
import style0 from "./BirthYearDisplayText.vue?vue&type=style&index=0&id=b8e5218c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8e5218c",
  null
  
)

component.options.__file = "BirthYearDisplayText.vue"
export default component.exports