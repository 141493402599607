//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import buttonMixin from './buttonMixin.js';

/**
 * KExternalLink creates a styled external link
 */
export default {
  name: 'KExternalLink',
  mixins: [buttonMixin],
  props: {
    /**
     * URL string
     */
    href: {
      type: String,
      required: true,
    },
    /**
     * Specifies that the file is meant to be downloaded, not displayed in a separate tab.
     */
    download: {
      type: String,
      required: false,
    },
  },
};

