//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import UiIcon from 'keen-ui/src/UiIcon';
import KTooltip from 'kolibri.coreVue.components.KTooltip';

export default {
  name: 'CoachContentLabel',
  components: {
    UiIcon,
    KTooltip,
  },
  mixins: [themeMixin],
  props: {
    value: {
      type: Number,
      default: 0,
    },
    // Show number next to label if a topic
    isTopic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    titleText() {
      if (this.isTopic) {
        return this.$tr('topicTitle', { count: this.value });
      }
      return this.$tr('coachResourceLabel');
    },
  },
  $trs: {
    coachResourceLabel: 'Coach resource',
    topicTitle:
      'Contains {count, number, integer} {count, plural, one {coach resource} other {coach resources}}',
  },
};

