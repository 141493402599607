//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

/**
 * Used to show determinate or indeterminate loading
 */
export default {
  name: 'KLinearLoader',
  mixins: [themeMixin],
  props: {
    /**
     * Whether there should be a delay before the loader displays
     */
    delay: {
      type: Boolean,
      required: true,
    },
    /**
     * Determinate or indeterminate
     */
    type: {
      type: String,
      default: 'indeterminate',
      validator(val) {
        return val === 'determinate' || val === 'indeterminate';
      },
    },
    progress: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    classes() {
      return [`ui-progress-linear--type-${this.type}`, this.delay ? 'delay' : ''];
    },

    moderatedProgress() {
      if (this.progress < 0) {
        return 0;
      }

      if (this.progress > 100) {
        return 100;
      }

      return this.progress;
    },
  },
};

