//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import { validateLinkObject } from 'kolibri.utils.validators';
import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';

export default {
  name: 'ImmersiveFullScreen',
  mixins: [responsiveWindow, themeMixin],
  props: {
    backPageLink: {
      type: Object,
      required: true,
      validator: validateLinkObject,
    },
    backPageText: {
      type: String,
    },
  },
};

