//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import { validateLinkObject } from 'kolibri.utils.validators';
import UiIcon from 'keen-ui/src/UiIcon';

/**
 Links for use inside the KNavbar
 */
export default {
  name: 'KNavbarLink',
  components: { UiIcon },
  mixins: [themeMixin],
  props: {
    /**
     * The text
     */
    title: {
      type: String,
      required: false,
    },
    /**
     * A router link object
     */
    link: {
      type: Object,
      required: true,
      validator: validateLinkObject,
    },
  },
  computed: {
    tabStyles() {
      return {
        color: this.$coreBgCanvas,
        ':hover': {
          'background-color': this.$coreActionDark,
        },
        ':focus': {
          ...this.$coreOutline,
          outlineOffset: '-6px',
        },
      };
    },
  },
};

