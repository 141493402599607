//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import ResponsiveElement from 'kolibri.coreVue.mixins.responsiveElement';
import { validateLinkObject } from 'kolibri.utils.validators';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import throttle from 'lodash/throttle';
import UiIconButton from 'kolibri.coreVue.components.UiIconButton';
import KRouterLink from 'kolibri.coreVue.components.KRouterLink';

const DROPDOWN_BTN_WIDTH = 55;
const DROPDOWN_SIDE_PADDING = 32; // pulled from .breadcrumbs-dropdown
const MAX_CRUMB_WIDTH = 300; // pulled from .breadcrumbs-visible-item class

/**
 * Used to aid deeply nested navigation of content channels, topics, and resources
 */
export default {
  name: 'KBreadcrumbs',
  components: { UiIconButton, KRouterLink },
  mixins: [ResponsiveElement],
  props: {
    /**
     * An array of objects, each with a 'text' attribute (String) and a
     * 'link' attribute (vue router link object). The 'link' attribute
     * of the last item in the array is optional and ignored.
     */
    items: {
      type: Array,
      required: true,
      validator(crumbItems) {
        // All must have text
        if (!crumbItems.every(crumb => Boolean(crumb.text))) {
          return false;
        }
        // All, but the last, must have a valid router link
        return crumbItems.slice(0, -1).every(crumb => validateLinkObject(crumb.link));
      },
    },
    /**
     * By default, the breadcrums will be hidden when the length of items is 1.
     * When set to 'true', a breadcrumb will be shown even when there is only one.
     */
    showSingleItem: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // Array of crumb objects.
    // Each object contains:
    // text, router-link 'to' object, vue ref, a resize sensor, and its collapsed state
    crumbs: [],
  }),
  computed: {
    collapsedCrumbs() {
      return this.crumbs.filter(crumb => crumb.collapsed === true).reverse();
    },
    parentWidth() {
      return this.elementWidth;
    },
    lastCrumbMaxWidth() {
      if (this.collapsedCrumbs.length) {
        return Math.min(this.parentWidth - DROPDOWN_BTN_WIDTH, MAX_CRUMB_WIDTH);
      }
      return Math.min(this.parentWidth, MAX_CRUMB_WIDTH);
    },
    collapsedCrumbMaxWidth() {
      return Math.min(this.parentWidth - DROPDOWN_SIDE_PADDING, MAX_CRUMB_WIDTH);
    },
  },
  watch: {
    items(val) {
      this.crumbs = Array.from(val);
      this.attachSensors();
    },
  },
  created() {
    this.crumbs = Array.from(this.items);
  },
  mounted() {
    this.attachSensors();
    this.$watch('parentWidth', this.throttleUpdateCrumbs);
  },

  beforeDestroy() {
    this.detachSensors();
  },
  methods: {
    attachSensors() {
      this.$nextTick(() => {
        const crumbRefs = filter(this.$refs, (value, key) => startsWith(key, 'crumb'));
        this.crumbs = this.crumbs.map((crumb, index) => {
          const updatedCrumb = crumb;
          updatedCrumb.ref = crumbRefs[index];
          updatedCrumb.sensor = new ResizeSensor(updatedCrumb.ref, this.throttleUpdateCrumbs);
          return updatedCrumb;
        });
        this.updateCrumbs();
      });
    },
    detachSensors() {
      this.crumbs.forEach(crumb => {
        crumb.sensor.detach(this.throttleUpdateCrumbs);
      });
    },
    updateCrumbs() {
      if (this.crumbs.length) {
        const tempCrumbs = Array.from(this.crumbs);
        let lastCrumbWidth = Math.ceil(tempCrumbs.pop().ref[0].getBoundingClientRect().width);
        let remainingWidth = this.parentWidth - DROPDOWN_BTN_WIDTH - lastCrumbWidth;
        let trackingIndex = this.crumbs.length - 2;

        while (tempCrumbs.length) {
          if (remainingWidth <= 0) {
            tempCrumbs.forEach((crumb, index) => {
              const updatedCrumb = crumb;
              updatedCrumb.collapsed = true;
              this.crumbs.splice(index, 1, updatedCrumb);
            });
            break;
          }

          lastCrumbWidth = Math.ceil(
            tempCrumbs[tempCrumbs.length - 1].ref[0].getBoundingClientRect().width
          );

          if (lastCrumbWidth > remainingWidth) {
            tempCrumbs.forEach((crumb, index) => {
              const updatedCrumb = crumb;
              updatedCrumb.collapsed = true;
              this.crumbs.splice(index, 1, updatedCrumb);
            });
            break;
          }

          remainingWidth -= lastCrumbWidth;
          const lastCrumb = tempCrumbs.pop();
          lastCrumb.collapsed = false;
          this.crumbs.splice(trackingIndex, 1, lastCrumb);
          trackingIndex -= 1;
        }
      }
    },
    throttleUpdateCrumbs: throttle(function updateCrumbs() {
      this.updateCrumbs();
    }, 100),
  },
};

