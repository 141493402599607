//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import fuzzysearch from 'fuzzysearch';
import startswith from 'lodash/startsWith';
import sortby from 'lodash/sortBy';
import UiIcon from 'keen-ui/src/UiIcon';
import KCircularLoader from 'kolibri.coreVue.components.KCircularLoader';

import { looseIndexOf, looseEqual } from 'keen-ui/src/helpers/util';
import { scrollIntoView, resetScroll } from 'keen-ui/src/helpers/element-scroll';
import config from 'keen-ui/src/config';
import KeenUiSelectOption from './KeenUiSelectOption.vue';

export default {
  name: 'KeenUiSelect',

  components: {
    UiIcon,
    KCircularLoader,
    KeenUiSelectOption,
  },
  mixins: [themeMixin],
  props: {
    name: String,
    value: {
      type: [String, Number, Object, Array],
      required: true,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: String,
    icon: String,
    iconPosition: {
      type: String,
      default: 'left', // 'left' or 'right'
    },
    label: String,
    floatingLabel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'basic', // 'basic' or 'image'
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    multipleDelimiter: {
      type: String,
      default: ', ',
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    filter: Function,
    disableFilter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noResults: {
      type: Boolean,
      default: false,
    },
    keys: {
      type: Object,
      default() {
        return config.data.UiSelect.keys;
      },
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    help: String,
    error: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      query: '',
      isActive: false,
      isTouched: false,
      highlightedOption: null,
      showDropdown: false,
      initialValue: JSON.stringify(this.value),
      quickMatchString: '',
      quickMatchTimeout: null,
    };
  },

  computed: {
    classes() {
      return [
        `ui-select-type-${this.type}`,
        `ui-select-icon-position-${this.iconPosition}`,
        { 'is-active': this.isActive },
        { 'is-invalid': this.invalid },
        { 'is-touched': this.isTouched },
        { 'is-disabled': this.disabled },
        { 'is-multiple': this.multiple },
        { 'has-label': this.hasLabel },
        { 'has-floating-label': this.hasFloatingLabel },
      ];
    },

    labelClasses() {
      return {
        'is-inline': this.hasFloatingLabel && this.isLabelInline,
        'is-floating': this.hasFloatingLabel && !this.isLabelInline,
      };
    },

    hasLabel() {
      return Boolean(this.label) || Boolean(this.$slots.default);
    },

    hasFloatingLabel() {
      return this.hasLabel && this.floatingLabel;
    },

    isLabelInline() {
      return this.value.length === 0 && !this.isActive;
    },

    hasFeedback() {
      return Boolean(this.help) || Boolean(this.error) || Boolean(this.$slots.error);
    },

    showError() {
      return this.invalid && (Boolean(this.error) || Boolean(this.$slots.error));
    },

    showHelp() {
      return !this.showError && (Boolean(this.help) || Boolean(this.$slots.help));
    },

    filteredOptions() {
      if (this.disableFilter) {
        return this.options;
      }

      return this.options.filter((option, index) => {
        if (this.filter) {
          return this.filter(option, this.query);
        }

        return this.defaultFilter(option, index);
      });
    },

    displayText() {
      if (this.multiple) {
        if (this.value.length > 0) {
          return this.value
            .map(value => value[this.keys.label] || value)
            .join(this.multipleDelimiter);
        }

        return '';
      }

      return this.value ? this.value[this.keys.label] || this.value : '';
    },

    hasDisplayText() {
      return Boolean(this.displayText.length);
    },

    hasNoResults() {
      if (this.loading || this.query.length === 0) {
        return false;
      }

      return this.disableFilter ? this.noResults : this.filteredOptions.length === 0;
    },

    submittedValue() {
      // Assuming that if there is no name, then there's no
      // need to computed the submittedValue
      if (!this.name || !this.value) {
        return;
      }

      if (Array.isArray(this.value)) {
        return this.value.map(option => option[this.keys.value] || option).join(',');
      }

      return this.value[this.keys.value] || this.value;
    },

    // Returns the index of the currently highlighted option
    highlightedIndex() {
      return this.options.findIndex(option => looseEqual(this.highlightedOption, option));
    },

    // Returns the index of the currently selected option, -1 if multi-select
    selectedIndex() {
      if (this.multiple) {
        return -1;
      }
      return this.options.findIndex(option => looseEqual(this.value, option));
    },

    // Returns an array containing the options and extra annotations
    annotatedOptions() {
      const options = JSON.parse(JSON.stringify(this.options));
      return options.map((option, index) => {
        // If not object, create object
        if (typeof option !== 'object') {
          option = {
            [this.keys.value]: option,
            [this.keys.label]: option,
          };
        }

        // Add index to object
        option.index = index;

        // Check if valid prev/next
        if (!option.disabled) {
          if (index < this.highlightedIndex) {
            option.validPreviousOption = true;
          } else if (index > this.highlightedIndex) {
            option.validNextOption = true;
          }
        }

        // Check if matches
        option.startsWith = startswith(
          option[this.keys.label].toLowerCase(),
          this.quickMatchString.toLowerCase()
        );

        return option;
      });
    },
    activeColorStyle() {
      if (this.isActive) {
        return {
          color: this.$coreActionNormal,
        };
      }
    },
    activeBorderStyle() {
      if (this.isActive) {
        return {
          borderBottomColor: this.$coreActionNormal,
        };
      }
    },
  },

  watch: {
    filteredOptions() {
      this.highlightedOption = this.filteredOptions[0];
      resetScroll(this.$refs.optionsList);
    },

    showDropdown() {
      if (this.showDropdown) {
        this.onOpen();
        this.$emit('dropdown-open');
      } else {
        this.onClose();
        this.$emit('dropdown-close');
      }
    },

    query() {
      this.$emit('query-change', this.query);
    },

    quickMatchString(string) {
      if (string) {
        if (this.quickMatchTimeout) {
          clearTimeout(this.quickMatchTimeout);
          this.quickMatchTimeout = null;
        }
        this.quickMatchTimeout = setTimeout(() => {
          this.quickMatchString = '';
        }, 500);
      }
    },
  },

  created() {
    if (!this.value || this.value === '') {
      this.setValue(null);
    }
  },

  mounted() {
    document.addEventListener('click', this.onExternalClick);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.onExternalClick);
  },

  methods: {
    setValue(value) {
      value = value ? value : this.multiple ? [] : '';

      this.$emit('input', value);
      this.$emit('change', value);
    },

    // Highlights the matching option on key input
    highlightQuickMatch(event) {
      // https://github.com/ccampbell/mousetrap/blob/master/mousetrap.js#L39
      const specialKeyCodes = [
        8,
        9,
        13,
        16,
        17,
        18,
        20,
        27,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        45,
        46,
        91,
        93,
        224,
      ];
      const keyCode = event.keyCode;
      if (specialKeyCodes.includes(keyCode)) {
        return;
      }

      const character = event.key.toString();
      if (this.hasSearch) {
        this.openDropdown();
      } else {
        this.quickMatchString += character;
        let matchingItems = this.annotatedOptions.filter(
          option => option.startsWith && !option.disabled
        );
        if (matchingItems.length !== 0) {
          matchingItems = sortby(matchingItems, [this.keys.label]);
          matchingItems = sortby(matchingItems, item => item[this.keys.label].length);
          this.highlightOption(this.options[matchingItems[0].index]);
        }
      }
    },

    // Highlights the previous valid option
    highlightPreviousOption() {
      const options = this.annotatedOptions;
      let validPreviousOptionIndex = -1;
      for (let i = 0; i < options.length; i++) {
        if (options[i].validPreviousOption) {
          validPreviousOptionIndex = i;
        }
      }
      if (validPreviousOptionIndex !== -1) {
        this.highlightOption(this.options[validPreviousOptionIndex]);
      }
    },

    // Highlights the next valid option
    highlightNextOption() {
      const options = this.annotatedOptions;
      const validNextOptionIndex = options.findIndex(option => option.validNextOption);
      if (validNextOptionIndex !== -1) {
        this.highlightOption(this.options[validNextOptionIndex]);
      }
    },

    // Highlights the option
    highlightOption(option, options = { autoScroll: true }) {
      if (
        !option ||
        option.disabled ||
        looseEqual(this.highlightedOption, option) ||
        this.$refs.options.length === 0
      ) {
        return;
      }

      this.highlightedOption = option;
      this.openDropdown();

      if (options.autoScroll) {
        const index = this.filteredOptions.findIndex(option =>
          looseEqual(this.highlightedOption, option)
        );
        const optionToScrollTo = this.$refs.options[index];
        if (optionToScrollTo) {
          this.scrollOptionIntoView(optionToScrollTo.$el);
        }
      }
    },

    selectHighlighted() {
      if (
        this.highlightedOption &&
        !this.highlightedOption.disabled &&
        this.$refs.options.length > 0
      ) {
        this.selectOption(this.highlightedOption);
      }
    },

    selectOption(option, options = { autoClose: true }) {
      if (!option || option.disabled) {
        return;
      }

      const shouldSelect = this.multiple && !this.isOptionSelected(option);

      if (this.multiple) {
        this.updateOption(option, { select: shouldSelect });
      } else {
        this.setValue(option);
      }

      this.$emit('select', option, {
        selected: this.multiple ? shouldSelect : true,
      });

      this.clearQuery();

      if (!this.multiple && options.autoClose) {
        this.closeDropdown();
      }
    },

    // Checks if option is highlighted
    isOptionHighlighted(option) {
      return looseEqual(this.highlightedOption, option);
    },

    isOptionSelected(option) {
      if (this.multiple) {
        return looseIndexOf(this.value, option) > -1;
      }
      return looseEqual(this.value, option);
    },

    updateOption(option, options = { select: true }) {
      let value = [];
      let updated = false;
      const i = looseIndexOf(this.value, option);

      if (options.select && i < 0) {
        value = this.value.concat(option);
        updated = true;
      }

      if (!options.select && i > -1) {
        value = this.value.slice(0, i).concat(this.value.slice(i + 1));
        updated = true;
      }

      if (updated) {
        this.setValue(value);
      }
    },

    defaultFilter(option) {
      const query = this.query.toLowerCase();
      let text = option[this.keys.label] || option;

      if (typeof text === 'string') {
        text = text.toLowerCase();
      }

      return fuzzysearch(query, text);
    },

    clearQuery() {
      this.query = '';
    },

    toggleDropdown() {
      this[this.showDropdown ? 'closeDropdown' : 'openDropdown']();
    },

    openDropdown() {
      if (this.disabled) {
        return;
      }

      if (this.highlightedIndex === -1) {
        this.highlightNextOption();
      }

      this.showDropdown = true;

      // IE: clicking label doesn't focus the select element
      // to set isActive to true
      if (!this.isActive) {
        this.isActive = true;
      }
    },

    closeDropdown(options = { autoBlur: false }) {
      this.showDropdown = false;
      this.query = '';
      if (!this.isTouched) {
        this.isTouched = true;
        this.$emit('touch');
      }

      if (options.autoBlur) {
        this.isActive = false;
      } else {
        this.$refs.label.focus();
      }
    },

    onMouseover(option) {
      if (this.showDropdown) {
        this.highlightOption(option, { autoScroll: false });
      }
    },

    onFocus(e) {
      if (this.isActive) {
        return;
      }

      this.isActive = true;
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.isActive = false;
      this.$emit('blur', e);

      if (this.showDropdown) {
        this.closeDropdown({ autoBlur: true });
      }
    },

    onOpen() {
      this.highlightedOption = this.multiple ? null : this.value;
      this.$nextTick(() => {
        this.$refs[this.hasSearch ? 'searchInput' : 'dropdown'].focus();
        const selectedOption = this.$refs.optionsList.querySelector('.is-selected');
        if (selectedOption) {
          this.scrollOptionIntoView(selectedOption);
        } else {
          this.scrollOptionIntoView(
            this.$refs.optionsList.querySelector('.ui-select-option:not(.is-disabled)')
          );
        }
      });
    },

    onClose() {
      this.highlightedOption = this.multiple ? null : this.value;
    },

    onExternalClick(e) {
      if (!this.$el.contains(e.target)) {
        if (this.showDropdown) {
          this.closeDropdown({ autoBlur: true });
        } else if (this.isActive) {
          this.isActive = false;
        }
      }
    },

    scrollOptionIntoView(optionEl) {
      scrollIntoView(optionEl, {
        container: this.$refs.optionsList,
        marginTop: 180,
      });
    },

    reset() {
      this.setValue(JSON.parse(this.initialValue));
      this.clearQuery();
      this.resetTouched();
      this.highlightedOption = null;
    },

    resetTouched(options = { touched: false }) {
      this.isTouched = options.touched;
    },
  },
};

