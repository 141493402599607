//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import UiIcon from 'keen-ui/src/UiIcon';

export default {
  name: 'CoreMenuOption',
  components: {
    UiIcon,
  },
  mixins: [themeMixin],
  props: {
    type: String,
    label: String,
    link: String,
    secondaryText: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'is-divider': this.isDivider,
        'is-disabled': this.disabled,
        'is-active': this.active,
      };
    },
    isDivider() {
      return this.type === 'divider';
    },

    active() {
      return window.location.pathname.startsWith(this.link);
    },
    optionStyle() {
      let color = '';
      if (!this.isDivider) {
        if (this.active) {
          color = this.$coreAccentColor;
        } else if (this.disabled) {
          color = this.$coreTextAnnotation;
        } else {
          color = this.$coreTextDefault;
        }
      }
      const bg = {
        backgroundColor: this.$coreGrey200,
      };
      return Object.assign(
        {
          color,
        },
        this.disabled
          ? {}
          : {
              ':hover': bg,
            },
        {
          ":focus body[modality='keyboard']": bg,
        }
      );
    },
  },
  methods: {
    conditionalEmit() {
      if (!this.link) {
        this.$emit('select');
      }
    },
  },
};

