import { render, staticRenderFns } from "./KGridItem.vue?vue&type=template&id=018d2f2c&scoped=true&"
import script from "./KGridItem.vue?vue&type=script&lang=js&"
export * from "./KGridItem.vue?vue&type=script&lang=js&"
import style0 from "./KGridItem.vue?vue&type=style&index=0&id=018d2f2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018d2f2c",
  null
  
)

component.options.__file = "KGridItem.vue"
export default component.exports