//
//
//
//
//
//
//
//
//
//
//
//
//
//


import KIcon from './KIcon';

export default {
  name: 'KBasicContentIcon',
  components: {
    KIcon,
  },
  props: {
    kind: {
      type: String,
      required: false,
    },
  },
};

