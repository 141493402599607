import { render, staticRenderFns } from "./KCheckbox.vue?vue&type=template&id=a9cc0c5c&scoped=true&"
import script from "./KCheckbox.vue?vue&type=script&lang=js&"
export * from "./KCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./KCheckbox.vue?vue&type=style&index=0&id=a9cc0c5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9cc0c5c",
  null
  
)

component.options.__file = "KCheckbox.vue"
export default component.exports