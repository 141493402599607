import { render, staticRenderFns } from "./SoftDeletedDisplayText.vue?vue&type=template&id=1a5f5a52&scoped=true&"
import script from "./SoftDeletedDisplayText.vue?vue&type=script&lang=js&"
export * from "./SoftDeletedDisplayText.vue?vue&type=script&lang=js&"
import style0 from "./SoftDeletedDisplayText.vue?vue&type=style&index=0&id=1a5f5a52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a5f5a52",
  null
  
)

component.options.__file = "SoftDeletedDisplayText.vue"
export default component.exports