//
//
//
//
//
//
//
//
//
//


import { now } from 'kolibri.utils.serverClock';
import KEmptyPlaceholder from 'kolibri.coreVue.components.KEmptyPlaceholder';

export default {
  name: 'ElapsedTime',
  components: {
    KEmptyPlaceholder,
  },
  props: {
    date: {
      type: Date,
      required: false,
    },
  },
  data: () => ({
    now: now(),
    timer: null,
  }),
  computed: {
    ceilingDate() {
      if (this.date > this.now) {
        return this.now;
      }
      return this.date;
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.now = now();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};

