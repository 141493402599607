//
//
//
//
//
//
//
//


import buttonMixin from './buttonMixin.js';

/**
 * KRouterLink creates a styled router-link
 */
export default {
  name: 'KRouterLink',
  mixins: [buttonMixin],
  props: {
    /**
     * vue-router link object
     */
    to: {
      required: true,
      type: Object,
    },
  },
};

