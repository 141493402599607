//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'KEmptyPlaceholder',
  mixins: [themeMixin],
};

