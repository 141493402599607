import { render, staticRenderFns } from "./BirthYearSelect.vue?vue&type=template&id=58810bda&scoped=true&"
import script from "./BirthYearSelect.vue?vue&type=script&lang=js&"
export * from "./BirthYearSelect.vue?vue&type=script&lang=js&"
import style0 from "./BirthYearSelect.vue?vue&type=style&index=0&id=58810bda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58810bda",
  null
  
)

component.options.__file = "BirthYearSelect.vue"
export default component.exports