import { render, staticRenderFns } from "./KPageContainer.vue?vue&type=template&id=152e6c43&scoped=true&"
import script from "./KPageContainer.vue?vue&type=script&lang=js&"
export * from "./KPageContainer.vue?vue&type=script&lang=js&"
import style0 from "./KPageContainer.vue?vue&type=style&index=0&id=152e6c43&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152e6c43",
  null
  
)

component.options.__file = "KPageContainer.vue"
export default component.exports