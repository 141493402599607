//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import UiIcon from 'keen-ui/src/UiIcon.vue';

import autosize from 'autosize';
import autofocus from 'keen-ui/src/directives/autofocus';

export default {
  name: 'KeenUiTextbox',
  components: {
    UiIcon,
  },

  directives: {
    autofocus,
  },
  mixins: [themeMixin],

  props: {
    name: String,
    placeholder: String,
    value: {
      type: [String, Number],
      default: '',
    },
    icon: String,
    iconPosition: {
      type: String,
      default: 'left', // 'left' or 'right'
    },
    label: String,
    floatingLabel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text', // all the possible HTML5 input types, except those that have a special UI
    },
    multiLine: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 2,
    },
    autocomplete: String,
    autofocus: {
      type: Boolean,
      default: false,
    },
    autosize: {
      type: Boolean,
      default: true,
    },
    min: Number,
    max: Number,
    step: {
      type: String,
      default: 'any',
    },
    maxlength: Number,
    enforceMaxlength: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    help: String,
    error: String,
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
      isTouched: false,
      initialValue: this.value,
      autosizeInitialized: false,
    };
  },

  $trs: {
    maxLengthCounter: '{current, number, integer}/{max, number, integer}',
  },

  computed: {
    classes() {
      return [
        `ui-textbox--icon-position-${this.iconPosition}`,
        { 'is-active': this.isActive },
        { 'is-invalid': this.invalid },
        { 'is-touched': this.isTouched },
        { 'is-multi-line': this.multiLine },
        { 'has-counter': this.maxlength },
        { 'is-disabled': this.disabled },
        { 'has-label': this.hasLabel },
        { 'has-floating-label': this.hasFloatingLabel },
      ];
    },

    labelClasses() {
      return {
        'is-inline': this.hasFloatingLabel && this.isLabelInline,
        'is-floating': this.hasFloatingLabel && !this.isLabelInline,
      };
    },

    hasLabel() {
      return Boolean(this.label) || Boolean(this.$slots.default);
    },

    hasFloatingLabel() {
      return this.hasLabel && this.floatingLabel;
    },

    isLabelInline() {
      return this.valueLength === 0 && !this.isActive;
    },

    minValue() {
      if (this.type === 'number' && this.min !== undefined) {
        return this.min;
      }

      return null;
    },

    maxValue() {
      if (this.type === 'number' && this.max !== undefined) {
        return this.max;
      }

      return null;
    },

    stepValue() {
      return this.type === 'number' ? this.step : null;
    },

    valueLength() {
      return this.value ? this.value.length : 0;
    },

    hasFeedback() {
      return Boolean(this.help) || Boolean(this.error) || Boolean(this.$slots.error);
    },

    showError() {
      return this.invalid && (Boolean(this.error) || Boolean(this.$slots.error));
    },

    showHelp() {
      return !this.showError && (Boolean(this.help) || Boolean(this.$slots.help));
    },
  },

  created() {
    // Normalize the value to an empty string if it's null
    if (this.value === null) {
      this.initialValue = '';
      this.updateValue('');
    }
  },

  mounted() {
    if (this.multiLine && this.autosize) {
      autosize(this.$refs.textarea);
      this.autosizeInitialized = true;
    }
  },

  beforeDestroy() {
    if (this.autosizeInitialized) {
      autosize.destroy(this.$refs.textarea);
    }
  },

  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },

    onChange(e) {
      this.$emit('change', this.value, e);
    },

    onFocus(e) {
      this.isActive = true;
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.isActive = false;
      this.$emit('blur', e);

      if (!this.isTouched) {
        this.isTouched = true;
        this.$emit('touch');
      }
    },

    onKeydown(e) {
      this.$emit('keydown', e);
    },

    onKeydownEnter(e) {
      this.$emit('keydown-enter', e);
    },

    reset() {
      // Blur the input if it's focused to prevent required errors
      // when it's value is reset
      if (
        document.activeElement === this.$refs.input ||
        document.activeElement === this.$refs.textarea
      ) {
        document.activeElement.blur();
      }

      this.updateValue(this.initialValue);
      this.resetTouched();
    },

    resetTouched(options = { touched: false }) {
      this.isTouched = options.touched;
    },

    refreshSize() {
      if (this.autosizeInitialized) {
        autosize.update(this.$refs.textarea);
      }
    },
  },
};

