import { render, staticRenderFns } from "./AuthMessage.vue?vue&type=template&id=6add7a60&scoped=true&"
import script from "./AuthMessage.vue?vue&type=script&lang=js&"
export * from "./AuthMessage.vue?vue&type=script&lang=js&"
import style0 from "./AuthMessage.vue?vue&type=style&index=0&id=6add7a60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6add7a60",
  null
  
)

component.options.__file = "AuthMessage.vue"
export default component.exports