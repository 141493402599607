//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';
import responsiveElement from 'kolibri.coreVue.mixins.responsiveElement';

export default {
  name: 'MultiPaneLayout',
  mixins: [responsiveWindow, responsiveElement],
  computed: {
    maxHeight() {
      const APP_BAR_HEIGHT = this.windowIsSmall ? 56 : 64;
      const PADDING = this.windowIsSmall ? 16 : 32;
      const MARGIN = 16;
      let maxHeight = this.windowHeight - APP_BAR_HEIGHT - PADDING * 2 - MARGIN;
      if (this.$refs.header) {
        maxHeight = maxHeight - this.$refs.header.clientHeight;
      }
      if (this.$refs.footer) {
        maxHeight = maxHeight - this.$refs.footer.clientHeight;
      }
      return maxHeight;
    },
  },
  methods: {
    scrollMainToTop() {
      this.$refs.main.scrollTop = 0;
    },
  },
};

