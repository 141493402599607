//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import UiIcon from 'keen-ui/src/UiIcon';

export default {
  name: 'KeenUiSelectOption',
  components: {
    UiIcon,
  },
  mixins: [themeMixin],

  props: {
    option: {
      type: [String, Object],
      required: true,
    },
    type: {
      type: String,
      default: 'basic', // 'basic' or 'image'
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    keys: {
      type: Object,
      default() {
        return {
          label: 'label',
          image: 'image',
        };
      },
    },
  },

  computed: {
    classes() {
      return [
        `ui-select-option--type-${this.type}`,
        { 'is-highlighted': this.highlighted },
        { 'is-selected': this.selected },
        { 'is-disabled': this.option.disabled },
      ];
    },

    imageStyle() {
      return { 'background-image': 'url(' + this.option[this.keys.image] + ')' };
    },
    selectedStyle() {
      if (this.selected) {
        return {
          color: this.$coreActionNormal,
        };
      }
    },
  },
};

