//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import CoachContentLabel from 'kolibri.coreVue.components.CoachContentLabel';

export default {
  name: 'AttemptLogList',
  components: {
    CoachContentLabel,
  },
  mixins: [themeMixin],
  $trs: {
    header: 'Answer history',
    today: 'Today',
    yesterday: 'Yesterday',
    daysAgo: '{ daysElapsed } days ago',
    question: 'Question { questionNumber, number }',
  },
  props: {
    attemptLogs: {
      type: Array,
      required: true,
    },
    selectedQuestionNumber: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToSelectedAttemptLog(this.selectedQuestionNumber);
    });
  },
  methods: {
    setSelectedAttemptLog(questionNumber) {
      this.$emit('select', questionNumber);
      this.scrollToSelectedAttemptLog(questionNumber);
    },
    isSelected(questionNumber) {
      return Number(this.selectedQuestionNumber) === questionNumber;
    },
    scrollToSelectedAttemptLog(questionNumber) {
      const selectedElement = this.$refs.attemptList.children[questionNumber];
      if (selectedElement) {
        const parent = this.$el.parentElement;
        parent.scrollTop =
          selectedElement.offsetHeight * (questionNumber + 1) - parent.offsetHeight / 2;
      }
    },
  },
};

