//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import isArray from 'lodash/isArray';
import Popper from './Popper';

/**
 * Used to create a tooltip.
 */
export default {
  name: 'KTooltip',
  components: {
    Popper,
  },
  mixins: [themeMixin],
  props: {
    /**
     * String of ref which tooltip will be positioned relative to.
     */
    reference: {
      type: String,
      required: true,
    },
    /**
     * Parent's refs object which should contain the reference.
     */
    refs: {
      type: Object,
      default: () => {},
    },
    /**
     * Whether or not tooltip is disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Placement of tooltip relative to reference element. Supports any popper.js placement string
     */
    placement: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    readyToInit() {
      return this.mounted && this.htmlElement;
    },
    options() {
      return {
        placement: this.placement,
      };
    },
    htmlElement() {
      let element = this.refs[this.reference];
      if (!element) {
        return null;
      }
      if (isArray(element)) {
        element = element[0];
      }
      if (element._isVue) {
        element = element.$el;
      }
      return element;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mounted = true;
    });
  },
};

