//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import UiToolbar from 'keen-ui/src/UiToolbar';
import UiIconButton from 'kolibri.coreVue.components.UiIconButton';
import { darken } from 'kolibri.utils.colour';
import { validateLinkObject } from 'kolibri.utils.validators';

export default {
  name: 'ImmersiveToolbar',
  components: {
    UiToolbar,
    UiIconButton,
  },
  mixins: [themeMixin],
  props: {
    appBarTitle: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'close',
      validator(val) {
        return ['close', 'arrow_back'].includes(val);
      },
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    route: {
      type: Object,
      required: false,
      validator: validateLinkObject,
    },
    primary: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    hasRoute() {
      return Boolean(this.route);
    },
    linkStyle() {
      const hoverAndFocus = {
        backgroundColor: this.primary
          ? this.$coreActionDark
          : darken(this.$coreTextDefault, '25%'),
      };
      return {
        backgroundColor: this.primary ? this.$coreActionNormal : this.$coreTextDefault,
        ':hover': hoverAndFocus,
      };
    },
  },
};

