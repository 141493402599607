import { render, staticRenderFns } from "./KRadioButton.vue?vue&type=template&id=5307ab91&scoped=true&"
import script from "./KRadioButton.vue?vue&type=script&lang=js&"
export * from "./KRadioButton.vue?vue&type=script&lang=js&"
import style0 from "./KRadioButton.vue?vue&type=style&index=0&id=5307ab91&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5307ab91",
  null
  
)

component.options.__file = "KRadioButton.vue"
export default component.exports